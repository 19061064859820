import React, { useState } from "react"
import { navigate } from "@reach/router"
import { Layout, Menu } from "antd"
import {
  UserOutlined,
  LineChartOutlined,
  SettingOutlined,
  DollarOutlined,
  FileImageOutlined,
} from "@ant-design/icons"
import { useQuery } from "@apollo/react-hooks"
import { GET_USER_BY_ID } from "../queries/users"
const { Sider, Content, Footer } = Layout

export default function L({ user, children }) {
  const [collapsed, setCollapsed] = useState(false)
  const { data } = useQuery(GET_USER_BY_ID, {
    variables: { id: user.sub },
  })

  const menuItems = [
    {
      key: "1",
      label: "Sales",
      onClick: () => navigate("/dashboard/sales"),
      icon: <LineChartOutlined />,
    },
    {
      key: "2",
      label: "Affiliates",
      onClick: () => navigate("/dashboard/affiliates"),
      icon: <UserOutlined />,
      disabled: data.users_by_pk.admin ? false : true,
    },
    {
      key: "3",
      label: "Commissions",
      onClick: () => navigate("/dashboard/commissions"),
      icon: <DollarOutlined />,
      disabled: data.users_by_pk.admin ? false : true,
    },
    {
      key: "4",
      label: "Assets",
      onClick: () => navigate("/dashboard/assets"),
      icon: <FileImageOutlined />,
    },
    {
      key: "5",
      label: "Account",
      onClick: () => navigate("/dashboard/account"),
      icon: <SettingOutlined />,
    },
  ]

  return (
    <Layout theme="light" style={{ minHeight: "100vh", fontSize: 16 }}>
      <Sider
        theme="dark"
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
        }}
        collapsible
        collapsed={collapsed}
        onCollapse={() => setCollapsed(!collapsed)}
      >
        <div style={{ margin: 24 }}>
          <img
            style={{ width: 130, display: collapsed ? "none" : "block" }}
            src="/robocall-logo.png"
            alt="Robocalls"
          />
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={["1"]}
          items={menuItems}
        />
      </Sider>
      <Layout
        style={{
          marginLeft: collapsed ? 80 : 200,
          transition: "all 0.3s",
        }}
      >
        <Content style={{ backgroundColor: "white", padding: 24 }}>
          {children}
        </Content>
        <Footer style={{ textAlign: "center", fontSize: 12, color: "gray" }}>
          Copyright &copy; {new Date().getFullYear()} Rrobocalls.cash
        </Footer>
      </Layout>
    </Layout>
  )
}
