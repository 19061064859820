import React, { useState, useEffect } from "react"
import {
  Typography,
  Avatar,
  Row,
  Col,
  Button,
  Space,
  Tag,
  Skeleton,
} from "antd"
import { LogoutOutlined } from "@ant-design/icons"
import { useQuery } from "@apollo/react-hooks"
import { GET_USER_BY_ID } from "../queries/users"
import Seo from "../components/seo"
import { logout } from "../utils/auth"
const { Title, Paragraph } = Typography

function Account({ user }) {
  const [refURL, setRefURL] = useState(null)
  const [status, setStatus] = useState(null)
  const [refId, setRefId] = useState(null)
  const { loading } = useQuery(GET_USER_BY_ID, {
    variables: { id: user.sub },
    onCompleted: data => {
      setStatus(data.users_by_pk.status)
      setRefId(data.users_by_pk.ref_id)
    },
  })

  useEffect(() => {
    if (user) {
      setRefURL(`https://${window.location.hostname}/ref/${refId}/home`)
    }
  }, [user, refId])

  function renderRefStatus() {
    if (!status) return null

    let color = "blue"
    if (status.name === "Pending") color = "orange"
    if (status.name === "Active") color = "green"
    if (status.name === "Inactive") color = "red"

    if (status.name !== "Active") {
      return <Tag color={color}>{status.name}</Tag>
    }

    return (
      <Paragraph copyable type="secondary">
        {refURL}
      </Paragraph>
    )
  }

  return (
    <>
      <Seo title="Account" />
      <div style={{ marginBottom: 16 }}>
        <Title level={2} style={{ margin: 0 }}>
          Account
        </Title>
      </div>
      <Skeleton active loading={loading}>
        <Row gutter={[16, 16]} align="middle">
          <Col span={24}>
            <Space>
              <Avatar size={64} src={user.picture} />
              <div>
                <div>{user.nickname}</div>
                <strong>{user.email}</strong>
              </div>
            </Space>
          </Col>
          <Col span={24}>
            <Title style={{ marginBottom: 0 }} level={5}>
              Reference URL
            </Title>
            {renderRefStatus()}
          </Col>
          <Col span={24}>
            <Button onClick={logout} type="primary" icon={<LogoutOutlined />}>
              Logout
            </Button>
          </Col>
        </Row>
      </Skeleton>
    </>
  )
}

export default Account
