import React, { useState } from "react"
import { reduce } from "lodash"
import { navigate } from "@reach/router"
import { useQuery } from "@apollo/react-hooks"
import moment from "moment"
import { Typography, Table, Button, Skeleton } from "antd"
import { GET_ACTIVE_USERS, GET_USERS_AGGREGATE } from "../queries/users"
import Seo from "../components/seo"
const { Text, Title } = Typography

function Commissions() {
  const [count, setCount] = useState(0)
  const { loading: usersLoading, data: usersData } = useQuery(GET_ACTIVE_USERS)
  const { loading: usersAggLoading } = useQuery(GET_USERS_AGGREGATE, {
    onCompleted: data => {
      const { count } = data.users_aggregate.aggregate
      setCount(count)
    },
  })
  const columns = [
    {
      title: "Reference ID",
      dataIndex: "ref_id",
      key: "ref_id",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: text => text,
    },
    {
      title: "Unpaid Commission",
      dataIndex: "sales",
      key: "sales",
      render: items => {
        let _total = reduce(
          items,
          (sum, n) => {
            let _ctotal = reduce(
              n.commission_fees,
              (cSum, cn) => cSum + cn.commission_fee,
              0
            )
            return sum + _ctotal
          },
          0
        )
        return <Text strong>${(_total / 100).toFixed(2)}</Text>
      },
    },
    {
      title: "Created",
      dataIndex: "created_at",
      key: "created_at",
      render: text => moment(text).format("MM/DD/YYYY"),
      sorter: (a, b) => moment(a.created_at) - moment(b.created_at),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (action, row) => {
        return (
          <Button
            onClick={() => navigate(`/dashboard/commissions/${row.ref_id}`)}
          >
            View Sales
          </Button>
        )
      },
    },
  ]

  return (
    <>
      <Seo title="Commissions" />
      <div style={{ marginBottom: 16 }}>
        <Title level={2} style={{ margin: 0 }}>
          Commissions
        </Title>
      </div>
      <Skeleton active loading={usersLoading || usersAggLoading}>
        {usersData && (
          <Table
            rowKey="id"
            columns={columns}
            dataSource={usersData.users}
            pagination={{ defaultCurrent: 1, total: count, pageSize: 50 }}
          />
        )}
      </Skeleton>
    </>
  )
}

export default Commissions
