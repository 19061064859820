/** @jsx jsx */
import { jsx, Heading, Input, Label, Button, Checkbox } from "theme-ui"
import { navigate } from "@reach/router"
import { useState } from "react"
import { Modal } from "antd"
import { graphql, useStaticQuery } from "gatsby"
import { useMutation, useQuery } from "@apollo/react-hooks"
import { UPDATE_USER, GET_USER_BY_ID } from "../queries/users"
import { Loading, ContentfulRichText } from "../components"

const QUERY = graphql`
  {
    contentfulInnerPages(slug: { eq: "affiliate-agreement-dashboard" }) {
      content {
        raw
      }
    }
  }
`

function ProfileForm({ user }) {
  const data = useStaticQuery(QUERY)
  const [checked, setChecked] = useState(false)
  const [visibleModal, setModalVisible] = useState(false)
  const [refId, setRefId] = useState("")
  const { loading } = useQuery(GET_USER_BY_ID, {
    variables: { id: user.sub },
    onCompleted: data => {
      const { phone, first_name, last_name, ref_id } = data.users_by_pk
      setRefId(ref_id)
      if (phone && first_name && last_name) {
        navigate("/dashboard/sales")
      }
    },
  })

  const [updateUser, { loading: updateLoading }] = useMutation(UPDATE_USER, {
    onCompleted: () => navigate("/dashboard/sales"),
  })

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    phone: "",
  })

  function handleSubmit(e) {
    e.preventDefault()
    updateUser({
      variables: {
        ...formData,
        id: user.sub,
        ref_id: refId,
      },
    })
  }

  function handleAgreement(e) {
    e.preventDefault()
    setModalVisible(true)
  }

  if (updateLoading || loading) return <Loading text="Loading" />

  return (
    <div
      sx={{
        maxWidth: 540,
        mx: "auto",
        p: 4,
      }}
    >
      <div sx={{ textAlign: "center" }}>
        <img style={{ width: 200 }} src="/robocall-logo.png" alt="Robocalls" />
      </div>
      <Heading
        sx={{
          fontSize: [3, 4],
          textAlign: ["left", "center"],
          mt: 4,
          mb: 2,
        }}
      >
        Please complete the required fields below
      </Heading>
      <form onSubmit={handleSubmit}>
        <FormItem>
          <Label>
            First name <span sx={{ color: "red" }}>*</span>
          </Label>
          <Input
            onChange={e =>
              setFormData({ ...formData, first_name: e.target.value })
            }
            type="text"
            value={formData.first_name}
            required
          />
        </FormItem>
        <FormItem>
          <Label>
            Last name <span sx={{ color: "red" }}>*</span>
          </Label>
          <Input
            onChange={e =>
              setFormData({ ...formData, last_name: e.target.value })
            }
            type="text"
            value={formData.last_name}
            required
          />
        </FormItem>
        <FormItem>
          <Label>
            Phone <span sx={{ color: "red" }}>*</span>
          </Label>
          <Input
            onChange={e => setFormData({ ...formData, phone: e.target.value })}
            type="tel"
            value={formData.phone}
            required
          />
        </FormItem>
        <FormItem>
          <Label>
            Email <span sx={{ color: "red" }}>*</span>
          </Label>
          <Input type="email" defaultValue={user.email} disabled />
        </FormItem>
        <FormItem>
          <Label>
            <Checkbox
              defaultChecked={checked}
              onChange={e => setChecked(e.target.checked)}
            />
            I have read and fully understand the{" "}
            <button
              onClick={handleAgreement}
              sx={{
                display: "inline-block",
                m: 0,
                p: 0,
                ml: 1,
                border: 0,
                color: "primary",
                cursor: "pointer",
                background: "transparent",
              }}
            >
              Affiliate Agreement
            </button>
          </Label>
        </FormItem>
        <FormItem sx={{ display: "flex", justifyContent: "flex-end", mt: 4 }}>
          <Button type="submit" disabled={!checked}>
            Submit
          </Button>
        </FormItem>
      </form>
      <Modal
        open={visibleModal}
        footer={null}
        onCancel={() => setModalVisible(false)}
      >
        <ContentfulRichText content={data.contentfulInnerPages.content} />
      </Modal>
    </div>
  )
}

function FormItem({ children, ...rest }) {
  return (
    <div sx={{ my: 3 }} {...rest}>
      {children}
    </div>
  )
}

export default ProfileForm
