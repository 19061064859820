import React, { useState } from "react"
import { Typography, Upload, Modal, Skeleton } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import { useQuery, useMutation } from "@apollo/react-hooks"
import axios from "axios"
import { GET_USER_BY_ID } from "../queries/users"
import { GET_ASSETS, INSERT_ASSET, DELETE_ASSET_BY_PK } from "../queries/assets"
import Seo from "../components/seo"
const { Title } = Typography

function Assets({ user }) {
  const [isAdmin, setIsAdmin] = useState(false)
  const [progress, setProgress] = useState(0)
  const [state, setState] = useState({
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
    fileList: [],
  })
  const { loading: userLoading } = useQuery(GET_USER_BY_ID, {
    variables: { id: user.sub },
    onCompleted: data => {
      setIsAdmin(data.users_by_pk.admin)
    },
  })
  const [insertAsset] = useMutation(INSERT_ASSET)
  const [deleteAsset] = useMutation(DELETE_ASSET_BY_PK)
  const { loading: assetsLoading } = useQuery(GET_ASSETS, {
    onCompleted: data => {
      setState({
        ...state,
        fileList: data.assets,
      })
    },
  })

  const handleCancel = () => {
    setState({ ...state, previewVisible: false })
  }

  const handleChange = async ({ file, fileList }) => {
    if (file.status === "removed") {
      await axios
        .post(
          "/.netlify/functions/delete-image",
          { id: file.public_id },
          { headers: { "Content-Type": "application/json" } }
        )
        .then(() => {
          deleteAsset({ variables: { id: file.id } })
        })
    }
    setState({ ...state, fileList })
  }

  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    setState({
      ...state,
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    })
  }

  const uploadImage = async options => {
    const { onSuccess, onError, file, onProgress } = options
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onprogress = event => {
      const percent = Math.floor((event.loaded / event.total) * 100)
      setProgress(percent)
      if (percent === 100) {
        setTimeout(() => setProgress(0), 1000)
      }
      onProgress({ percent: (event.loaded / event.total) * 100 })
    }
    reader.onloadend = () => {
      axios
        .post(
          "/.netlify/functions/upload-image",
          { image: reader.result },
          { headers: { "Content-Type": "application/json" } }
        )
        .then(result => {
          const { data } = result
          const props = {
            ...data,
            uid: data.asset_id,
            name: file.name,
            status: "done",
          }
          insertAsset({ variables: { object: props } })
          onSuccess("Ok")
        })
        .catch(error => {
          onError({ error })
        })
    }
  }

  const { previewVisible, previewImage, fileList, previewTitle } = state

  return (
    <>
      <Seo title="Assets" />
      <div style={{ marginBottom: 16 }}>
        <Title level={2} style={{ margin: 0 }}>
          Assets
        </Title>
      </div>
      <Skeleton active loading={userLoading || assetsLoading}>
        <Upload
          percent={progress}
          disabled={!isAdmin}
          accept="image/*"
          customRequest={uploadImage}
          listType="picture-card"
          showUploadList={{
            showDownloadIcon: true,
            showRemoveIcon: isAdmin ? true : false,
          }}
          fileList={fileList}
          onPreview={handlePreview}
          onChange={handleChange}
        >
          {<UploadButton />}
        </Upload>
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img
            alt={previewTitle}
            style={{ width: "100%" }}
            src={previewImage}
          />
        </Modal>
      </Skeleton>
    </>
  )
}

const UploadButton = () => {
  return (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )
}

const getBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

export default Assets
