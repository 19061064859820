import gql from "graphql-tag"

export const GET_STATUS = gql`
  {
    status {
      id
      name
    }
  }
`
