import React, { useState, useRef, useEffect } from "react"
import { useQuery } from "@apollo/react-hooks"
import {
  Table,
  Skeleton,
  Card,
  Typography,
  Space,
  Row,
  Col,
  Input,
  Button,
} from "antd"
import Highlighter from "react-highlight-words"
import { LineChartOutlined, SearchOutlined } from "@ant-design/icons"
import moment from "moment"
import Seo from "../components/seo"
import {
  GET_SALES,
  GET_SALES_AGGREGATE,
  RANGE_SALES_TOTAL,
} from "../queries/sales"
import { GET_USER_COUNT, GET_USER_BY_ID } from "../queries/users"
const { Title, Text } = Typography

function Sales({ user }) {
  const [windowHeight, setWindowHeight] = useState(768)
  const [total, setTotal] = useState(0.0)
  const [count, setCount] = useState(0)
  const [weeklyTotal, setWeeklyTotal] = useState(0.0)
  const [monthTotal, setMonthTotal] = useState(0.0)
  const [isAdmin, setIsAdmin] = useState(false)
  const [search, setSearch] = useState({
    searchText: "",
    searchColumn: 0,
  })
  const searchNode = useRef(null)
  const { loading: userLoading } = useQuery(GET_USER_BY_ID, {
    variables: { id: user.sub },
    onCompleted: data => {
      setIsAdmin(data.users_by_pk.admin)
    },
  })
  const { loading: userCountLoading, data: userCountData } =
    useQuery(GET_USER_COUNT)
  const { loading: salesLoading, data: salesData } = useQuery(GET_SALES)
  const { loading: salesTotalLoading } = useQuery(GET_SALES_AGGREGATE, {
    onCompleted: data => {
      const { sum, count } = data.sales_aggregate.aggregate
      setTotal((sum.total / 100).toFixed(2))
      setCount(count)
    },
  })
  const { loading: currentWeekLoading } = useQuery(RANGE_SALES_TOTAL, {
    variables: {
      _gte: moment().isoWeekday("Monday").format("YYYY-MM-DD"),
      _lte: moment().isoWeekday("Sunday").format("YYYY-MM-DD"),
    },
    onCompleted: data => {
      setWeeklyTotal(
        (data.sales_aggregate.aggregate.sum.total / 100).toFixed(2)
      )
    },
  })
  const { loading: currentMonthLoading } = useQuery(RANGE_SALES_TOTAL, {
    variables: {
      _gte: moment().startOf("month").format("YYYY-MM-DD"),
      _lte: moment().endOf("month").format("YYYY-MM-DD"),
    },
    onCompleted: data => {
      setMonthTotal((data.sales_aggregate.aggregate.sum.total / 100).toFixed(2))
    },
  })

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchNode.current = node
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false })
              setSearch({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              })
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
          width: 24,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchNode.current.select(), 100)
      }
    },
    render: text =>
      search.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[search.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  })

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearch({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    })
  }

  const handleReset = clearFilters => {
    clearFilters()
    setSearch({ searchText: "" })
  }

  const columns = [
    {
      title: "Affiliate",
      dataIndex: "ref_id",
      key: "ref_id",
      ...getColumnSearchProps("ref_id"),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ...getColumnSearchProps("email"),
      render: text => text,
    },
    {
      title: "Created",
      dataIndex: "created_at",
      key: "created_at",
      width: "12%",
      render: text => moment(text).format("MM/DD/YYYY"),
      sorter: (a, b) => moment(a.created_at) - moment(b.created_at),
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      width: "12%",
      render: text => <strong>${(text / 100).toFixed(2)}</strong>,
      sorter: (a, b) => a.total - b.total,
    },
  ]

  useEffect(() => {
    if (window) {
      setWindowHeight(window.innerHeight)
    }
  }, [setWindowHeight])

  return (
    <>
      <Seo title="Sales" />
      <div style={{ marginBottom: 16 }}>
        <Title level={2} style={{ margin: 0 }}>
          Sales
        </Title>
        <Text type="secondary">Dashboard sales report</Text>
      </div>
      {isAdmin && (
        <Skeleton
          active
          loading={
            salesLoading ||
            salesTotalLoading ||
            userLoading ||
            userCountLoading ||
            currentWeekLoading ||
            currentMonthLoading
          }
        >
          <Row style={{ marginBottom: 16 }}>
            <Col span={8}>
              <div style={{ marginRight: 10 }}>
                <Card
                  bodyStyle={{
                    backgroundColor: "#1EBF36",
                    padding: "12px 24px",
                  }}
                >
                  <Space align="start">
                    <LineChartOutlined
                      style={{
                        fontSize: 30,
                        marginTop: 6,
                        marginRight: 10,
                        color: "white",
                      }}
                    />
                    <div>
                      <Title level={3} style={{ marginBottom: 0 }}>
                        <span style={{ color: "white" }}>${weeklyTotal}</span>
                      </Title>
                      <Text style={{ textTransform: "uppercase" }}>
                        <span style={{ color: "white" }}>
                          Current Week Sales
                        </span>
                      </Text>
                    </div>
                  </Space>
                </Card>
              </div>
            </Col>
            <Col span={8}>
              <div style={{ marginRight: 10 }}>
                <Card
                  bodyStyle={{
                    backgroundColor: "#1EBF36",
                    padding: "12px 24px",
                  }}
                >
                  <Space align="start">
                    <LineChartOutlined
                      style={{
                        fontSize: 30,
                        marginTop: 6,
                        marginRight: 10,
                        color: "white",
                      }}
                    />
                    <div>
                      <Title level={3} style={{ marginBottom: 0 }}>
                        {userCountData && (
                          <span style={{ color: "white" }}>${monthTotal}</span>
                        )}
                      </Title>
                      <Text style={{ textTransform: "uppercase" }}>
                        <span style={{ color: "white" }}>
                          Current Month Sales
                        </span>
                      </Text>
                    </div>
                  </Space>
                </Card>
              </div>
            </Col>
            <Col span={8}>
              <div>
                <Card
                  bodyStyle={{
                    backgroundColor: "#1EBF36",
                    padding: "12px 24px",
                  }}
                >
                  <Space align="start">
                    <LineChartOutlined
                      style={{
                        fontSize: 30,
                        marginTop: 6,
                        marginRight: 10,
                        color: "white",
                      }}
                    />
                    <div>
                      <Title level={3} style={{ marginBottom: 0 }}>
                        <span style={{ color: "white" }}>${total}</span>
                      </Title>
                      <Text style={{ textTransform: "uppercase" }}>
                        <span style={{ color: "white" }}>Total Sales</span>
                      </Text>
                    </div>
                  </Space>
                </Card>
              </div>
            </Col>
          </Row>
        </Skeleton>
      )}
      <Skeleton active loading={salesLoading}>
        {salesData && (
          <Table
            rowKey="id"
            columns={columns}
            dataSource={salesData.sales}
            pagination={{
              defaultCurrent: 1,
              total: count,
              pageSize: 15,
            }}
            scroll={{ y: windowHeight }}
          />
        )}
      </Skeleton>
    </>
  )
}

export default Sales
