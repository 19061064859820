import { gql } from "graphql-tag"

export const INSERT_ASSET = gql`
  mutation InsertAsset($object: assets_insert_input!) {
    insert_assets_one(object: $object) {
      id
    }
  }
`

export const GET_ASSETS = gql`
  query GetAssets {
    assets {
      asset_id
      id
      name
      format
      public_id
      secure_url
      status
      uid
      url
    }
  }
`

export const DELETE_ASSET_BY_PK = gql`
  mutation DeleteAsset($id: Int!) {
    delete_assets_by_pk(id: $id) {
      id
    }
  }
`
