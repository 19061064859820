import gql from "graphql-tag"

export const INSERT_COMMISSIONS_ONE = gql`
  mutation InsertCommissionsOne(
    $invoice_id: String!
    $sales_ids: json!
    $ref_id: String!
    $total: numeric!
  ) {
    insert_commissions_one(
      object: {
        invoice_id: $invoice_id
        sales_ids: $sales_ids
        ref_id: $ref_id
        total: $total
      }
    ) {
      id
    }
  }
`
