import React, { useState, useRef, useEffect } from "react"
import { useQuery, useMutation } from "@apollo/react-hooks"
import axios from "axios"
import moment from "moment"
import {
  Typography,
  Table,
  Tag,
  Menu,
  Dropdown,
  Button,
  message,
  Space,
  Modal,
  Row,
  Col,
  Form,
  Input,
  Skeleton,
} from "antd"
import {
  DownOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons"
import Highlighter from "react-highlight-words"
import {
  GET_USERS,
  UPDATE_USER_STATUS,
  GET_USERS_AGGREGATE,
  DELETE_USER_BY_PK,
  UPDATE_USER,
  UPDATE_USER_SENT_W9,
} from "../queries/users"
import { GET_STATUS } from "../queries/status"
import Seo from "../components/seo"
const { Title, Text } = Typography

function Affiliates() {
  const [windowHeight, setWindowHeight] = useState(768)
  const [selectedRow, setSelectedRow] = useState(null)
  const [count, setCount] = useState(0)
  const { loading: statusLoading, data: statusData } = useQuery(GET_STATUS)
  const { loading: usersLoading, data: usersData } = useQuery(GET_USERS)
  const searchNode = useRef(null)
  const [search, setSearch] = useState({
    searchText: "",
    searchColumn: 0,
  })

  const { loading: usersAggLoading } = useQuery(GET_USERS_AGGREGATE, {
    onCompleted: data => {
      const { count } = data.users_aggregate.aggregate
      setCount(count)
    },
  })

  const [updateUserStatus] = useMutation(UPDATE_USER_STATUS, {
    onCompleted: data => {
      const { status, name } = data.update_users_by_pk
      message.info(`Status updated to ${status.name} to user ${name}`)
    },
  })

  const [updateUserSentW9] = useMutation(UPDATE_USER_SENT_W9)

  const [deleteUser] = useMutation(DELETE_USER_BY_PK, {
    onCompleted: data => {
      const { ref_id } = data.delete_users_by_pk
      message.info(`User ${ref_id} has been deleted successfully.`)
    },
    refetchQueries: [{ query: GET_USERS }],
  })

  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: data => {
      const { ref_id } = data.update_users_by_pk
      message.info(`User ${ref_id} has been updated successfully.`)
      setIsEditVisible(false)
      setIsUpdating(false)
    },
    refetchQueries: [{ query: GET_USERS }],
  })

  const status = obj => {
    let color = "blue"
    if (obj.name === "Pending") color = "orange"
    if (obj.name === "Active") color = "green"
    if (obj.name === "Inactive") color = "red"
    return <Tag color={color}>{obj.name}</Tag>
  }

  const [isViewVisible, setIsViewVisible] = useState(false)
  const [isEditVisible, setIsEditVisible] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const [form] = Form.useForm()

  const showViewModal = row => {
    setSelectedRow(row)
    setIsViewVisible(true)
  }

  const showEditModal = row => {
    setSelectedRow(row)

    form.setFieldsValue({
      ref_id: row.ref_id,
      first_name: row.first_name,
      last_name: row.last_name,
      phone: row.phone,
    })

    setIsEditVisible(true)
  }

  const onEditFinish = values => {
    setIsUpdating(true)
    updateUser({
      variables: {
        id: selectedRow.id,
        ...values,
      },
    })
  }

  const handleMenuClick = e => {
    updateUserStatus({
      variables: {
        id: selectedRow.id,
        status_id: parseInt(e.key),
      },
    })
  }

  const deleteConfirm = row => {
    Modal.confirm({
      title: <Text strong>Are you sure you want to delete affiliate?</Text>,
      content:
        "This will also delete all related data from this user. Be careful, this process cannot be undone.",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: () => deleteUser({ variables: { id: row.id } }),
    })
  }

  const sendW9Confirm = row => {
    Modal.confirm({
      title: <Text strong>Are you sure you want send W9 form?</Text>,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: async () => {
        try {
          await axios({
            method: "post",
            url: "/.netlify/functions/sendw9",
            data: {
              email: row.email,
              name: row.name,
            },
          })

          // update sent_w9
          updateUserSentW9({
            variables: {
              id: row.id,
              sent_w9: true,
            },
          })

          message.info(`Successfully sent to ${row.email}`)
        } catch (error) {
          console.log(error)
          message.error(`Something went wrong!`)
        }
      },
    })
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearch({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    })
  }

  const handleReset = clearFilters => {
    clearFilters()
    setSearch({ searchText: "" })
  }

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchNode.current = node
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false })
              setSearch({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              })
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
          width: 24,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchNode.current.select(), 100)
      }
    },
    render: text =>
      search.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[search.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  })

  const columns = [
    {
      title: "Reference ID",
      dataIndex: "ref_id",
      key: "ref_id",
      width: "15%",
      render: text => text,
      ...getColumnSearchProps("ref_id"),
    },
    {
      title: "First name",
      dataIndex: "first_name",
      key: "first_name",
      width: "12%",
      render: text => text,
      ...getColumnSearchProps("first_name"),
    },
    {
      title: "Last name",
      dataIndex: "last_name",
      key: "last_name",
      width: "12%",
      render: text => text,
      ...getColumnSearchProps("last_name"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "15%",
      render: text => text,
      ...getColumnSearchProps("email"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "7%",
      render: tag => status(tag),
    },
    {
      title: "Action",
      dataIndex: "status",
      key: "status",
      render: (status, row) => {
        let isDisabled

        if (row.status.name === "Active") {
          if (row.sent_w9 === true) {
            isDisabled = true
          }
        }

        if (row.status.name === "Pending") isDisabled = true
        if (row.status.name === "Inactive") isDisabled = true

        const menuItems = statusData.status.map(item => {
          return {
            key: item.id,
            label: item.name,
          }
        })

        const menuProps = {
          menuItems,
          onClick: handleMenuClick,
        }

        return (
          <Space>
            <Button onClick={() => showViewModal(row)}>View</Button>
            <Button onClick={() => showEditModal(row)}>Edit</Button>
            <Button onClick={() => deleteConfirm(row)} type="primary" danger>
              Delete
            </Button>
            <Button onClick={() => sendW9Confirm(row)} disabled={isDisabled}>
              Send W-9
            </Button>
            <Dropdown trigger={["click"]} menu={menuProps}>
              <Button style={{ width: 120 }}>
                <Space>
                  {status.name}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          </Space>
        )
      },
    },
  ]

  useEffect(() => {
    if (window) {
      setWindowHeight(window.innerHeight)
    }
  }, [setWindowHeight])

  return (
    <>
      <Seo title="Affiliates" />
      <div style={{ marginBottom: 16 }}>
        <Title level={2} style={{ margin: 0 }}>
          Affiliates
        </Title>
      </div>
      <Skeleton
        active
        loading={usersLoading || statusLoading || usersAggLoading}
      >
        {usersData && (
          <Table
            rowKey="id"
            columns={columns}
            dataSource={usersData.users}
            pagination={{ defaultCurrent: 1, total: count, pageSize: 15 }}
            scroll={{ y: windowHeight }}
          />
        )}
        {selectedRow && (
          <>
            <Modal
              title="View affiliate"
              open={isViewVisible}
              onCancel={() => setIsViewVisible(false)}
              footer={null}
            >
              <Row gutter={16}>
                <Col span={24}>
                  <div style={gutterV}>
                    <Text type="secondary">Reference ID</Text>
                    <div>
                      <Text strong>{selectedRow.ref_id}</Text>
                    </div>
                  </div>
                </Col>
                <Col span={12}>
                  <div style={gutterV}>
                    <Text type="secondary">First name</Text>
                    <div>
                      <Text strong>{selectedRow.first_name}</Text>
                    </div>
                  </div>
                </Col>
                <Col span={12}>
                  <div style={gutterV}>
                    <Text type="secondary">Last name</Text>
                    <div>
                      <Text strong>{selectedRow.last_name}</Text>
                    </div>
                  </div>
                </Col>
                <Col span={12}>
                  <div style={gutterV}>
                    <Text type="secondary">Phone</Text>
                    <div>
                      <Text strong>{selectedRow.phone}</Text>
                    </div>
                  </div>
                </Col>
                <Col span={12}>
                  <div style={gutterV}>
                    <Text type="secondary">Email</Text>
                    <div>
                      <Text strong>{selectedRow.email}</Text>
                    </div>
                  </div>
                </Col>
                <Col span={12}>
                  <div style={gutterV}>
                    <Text type="secondary">Created at</Text>
                    <div>
                      <Text strong>
                        {moment(selectedRow.created_at).format("MM/DD/YYYY")}
                      </Text>
                    </div>
                  </div>
                </Col>
                <Col span={12}>
                  <div style={gutterV}>
                    <Text type="secondary">Status</Text>
                    <div>{status(selectedRow.status)}</div>
                  </div>
                </Col>
              </Row>
            </Modal>
            <Modal
              title="Edit affiliate"
              open={isEditVisible}
              footer={null}
              onCancel={() => {
                setIsEditVisible(false)
                form.resetFields()
              }}
            >
              <Form
                form={form}
                name="updateAffiliate"
                layout="vertical"
                onFinish={onEditFinish}
                autoComplete="off"
              >
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      label="Reference ID"
                      name="ref_id"
                      rules={[
                        {
                          required: true,
                          message: "Please input your reference id!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="First name"
                      name="first_name"
                      rules={[
                        {
                          required: true,
                          message: "Please input your first name!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Last name"
                      name="last_name"
                      rules={[
                        {
                          required: true,
                          message: "Please input your last name!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Phone"
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: "Please input your phone number!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        disabled={isUpdating ? true : false}
                      >
                        {isUpdating ? "Please wait" : "Submit"}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Modal>
          </>
        )}
      </Skeleton>
    </>
  )
}

const gutterV = { padding: "8px 0" }

export default Affiliates
