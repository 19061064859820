import React, { useState } from "react"
import { useMutation, useQuery } from "@apollo/react-hooks"
import {
  Typography,
  Table,
  Tag,
  Row,
  Col,
  Button,
  Space,
  Modal,
  message,
  Input,
  Skeleton,
} from "antd"
import { reduce, isEmpty } from "lodash"
import Seo from "../components/seo"
import {
  GET_SALES_BY_USER_ID,
  UPDATE_SALES_COMMISSION_PAID,
} from "../queries/sales"
import { INSERT_COMMISSIONS_ONE } from "../queries/commissions"
const { Title, Text } = Typography

function CommissionsSingle({ refId }) {
  const [total, setTotal] = useState(0)
  const [salesIds, setSalesIds] = useState([])
  const [invoiceId, setInvoiceId] = useState("")

  const [modal, setModal] = useState({
    visible: false,
    loading: false,
  })

  const { loading: salesLoading, data: salesData } = useQuery(
    GET_SALES_BY_USER_ID,
    { variables: { ref_id: refId } }
  )

  const [insertCommission] = useMutation(INSERT_COMMISSIONS_ONE)

  const [updateSalesPaid] = useMutation(UPDATE_SALES_COMMISSION_PAID, {
    onCompleted: () => {
      // reset and toast message
      setInvoiceId("")
      setSalesIds([])
      setModal({ loading: false, visible: false })
      message.info("Successfully updated!")
    },
    refetchQueries: [
      { query: GET_SALES_BY_USER_ID, variables: { ref_id: refId } },
    ],
  })

  const columns = [
    {
      title: "Sales ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Invoice ID",
      dataIndex: "invoice_id",
      key: "invoice_id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: text => <a href={`mailto:${text}`}>{text}</a>,
    },
    {
      title: "Status",
      dataIndex: "commission_paid",
      key: "commission_paid",
      render: paid => {
        let color = "orange"
        let text = paid ? "Paid" : "Unpaid"
        if (paid) color = "green"
        return <Tag color={color}>{text}</Tag>
      },
    },
    {
      title: "Total",
      dataIndex: "commission_fees",
      key: "commission_fees",
      render: fees => {
        let _fees = reduce(fees, (sum, n) => sum + n.commission_fee, 0)
        return <Text strong>${(_fees / 100).toFixed(2)}</Text>
      },
    },
  ]

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      let _total = reduce(
        selectedRows,
        (sum, n) => {
          let _ctotal = reduce(
            n.commission_fees,
            (cSum, cn) => cSum + cn.commission_fee,
            0
          )
          return sum + _ctotal
        },
        0
      )
      setTotal(_total)
      setSalesIds(selectedRowKeys)
    },
    getCheckboxProps: sale => ({
      disabled: sale.commission_paid,
    }),
  }

  function onOkHandler() {
    setModal({ ...modal, loading: true })
    insertCommission({
      variables: {
        invoice_id: invoiceId,
        sales_ids: JSON.stringify(salesIds),
        ref_id: refId,
        total: total,
      },
    })
    updateSalesPaid({
      variables: {
        sales_ids: salesIds,
      },
    })
  }

  return (
    <>
      <Seo title="Commission" />
      <Skeleton active loading={salesLoading}>
        <Modal
          visible={modal.visible}
          okText="Update"
          footer={[
            <Button
              key="1"
              onClick={() => setModal({ ...modal, visible: false })}
            >
              Cancel
            </Button>,
            <Button
              key="update"
              type="primary"
              onClick={onOkHandler}
              loading={modal.loading}
              disabled={isEmpty(invoiceId) ? true : false}
            >
              Update
            </Button>,
          ]}
        >
          <Text strong>
            Invoice ID <Text type="danger">*</Text>
          </Text>
          <Input
            placeholder="Invoice ID"
            value={invoiceId}
            onChange={e => setInvoiceId(e.target.value)}
          />
        </Modal>
        <Row
          style={{ marginBottom: 16 }}
          justify="space-between"
          align="middle"
        >
          <Col>
            <Title level={2} style={{ margin: 0 }}>
              Commission
            </Title>
          </Col>
          <Col>
            <Space>
              <Title style={{ marginBottom: 0 }} level={3}>
                Total <Text type="success">${(total / 100).toFixed(2)}</Text>
              </Title>
              <Button
                onClick={() => setModal({ ...modal, visible: true })}
                type="primary"
                disabled={total <= 0 ? true : false}
              >
                Update
              </Button>
            </Space>
          </Col>
        </Row>
        {salesData && (
          <Table
            rowKey="id"
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
            columns={columns}
            dataSource={salesData.sales}
            pagination={false}
          />
        )}
      </Skeleton>
    </>
  )
}

export default CommissionsSingle
