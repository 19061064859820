import "antd/dist/antd.css"
import React from "react"
import { Router } from "@reach/router"
import { ApolloProvider } from "@apollo/client"
import { client } from "../apollo/client"
import { login, isAuthenticated, getProfile } from "../utils/auth"
import Layout from "../dashboard/layout"
import Sales from "../dashboard/sales"
import Affiliates from "../dashboard/affiliates"
import Account from "../dashboard/account"
import Assets from "../dashboard/assets"
import Commissions from "../dashboard/commissions"
import CommissionsSingle from "../dashboard/commissions-single"
import ProfileForm from "../dashboard/profile-form"

function Dashboard() {
  const user = getProfile()

  return (
    <ApolloProvider client={client}>
      <Router primary={false}>
        {isAuthenticated() ? (
          <>
            <ProfileForm path="/dashboard/profile" user={user} />
            <Layout path="/dashboard" user={user}>
              <Sales default user={user} />
              <Affiliates path="affiliates" />
              <Commissions path="commissions" />
              <CommissionsSingle path="commissions/:refId" />
              <Assets path="assets" user={user} />
              <Account path="account" user={user} />
            </Layout>
          </>
        ) : (
          login()
        )}
      </Router>
    </ApolloProvider>
  )
}

export default Dashboard
